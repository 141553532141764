import { Injectable } from "@angular/core";
import { Workbook } from "exceljs";
import * as fs from "file-saver";
import { DatePipe } from "@angular/common";
import {
  CHANNEL_EXCEL_HEADER,
  CITY_EXCEL_HEADER,
  CITY_UPLOAD_HEADER,
  GEOGRAPHY_EXCEL_HEADER,
  LOYALTY_PRODUCT_HEADER,
  ORDER_DETAILS_EXCEL,
  POINT_STRUCTURE_EXCEL,
  REGION_EXCEL_HEADER,
  REGION_UPLOAD_HEADER,
  STATE_EXCEL_HEADER,
  STATE_UPLOAD_HEADER,
  USERS_EXCEL,
  COTEGORY_EXCCEL,
  USER_EXCEL,
  WHITELISTING_EXCEL,
  PRIVILEGE_STRUCTURE_EXCEL,
  OFFER_CODE_HEADER,
  BRANCH_EXCEL
} from "src/app/hubadmin/shared/models/mock-data";
import { BrandModel } from "src/app/hubadmin/shared/models/brands";
import { GeographyModel } from "src/app/hubadmin/shared/models/geography";
import { ChannelModel } from "src/app/hubadmin/shared/models/channel";
import { RegionModel } from "src/app/hubadmin/shared/models/region";
import { UserRoleModel } from "src/app/hubadmin/shared/models/user-roles";

@Injectable({
  providedIn: "root",
})
export class ExportExcelService {
  // Redmeption
  TargetHeaders = USERS_EXCEL;


  constructor(private datePipe: DatePipe) { }

  pipe = new DatePipe("en-US");


  public DownloadCommonExcel(name, sheetname, tableData, excelArray) {
    let header = tableData.headers

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(sheetname, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(header);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "629CCA" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Arial",
        size: 10,
        color: { argb: "FFFFFF" },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns = excelArray;

    if (tableData.length > 0) {
      tableData.forEach((d) => {
        let row = worksheet.addRow(d);
        (row.font = {
          bold: false,
          name: "Arial",
          size: 10,
        }),
          (row.alignment = {
            vertical: "middle",
            horizontal: "center",
          });

      });
    }

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        name +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

}


